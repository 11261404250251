import _is from "./is";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var is = _is;

exports = function attr(name, value) {
  var args = arguments.length;
  return !is.str(name) && args == 2 ? attr(arguments[1]).call(this || _global, arguments[0]) : !is.str(name) && args == 3 ? attr(arguments[1], arguments[2]).call(this || _global, arguments[0]) : function (el) {
    var ctx = this || _global || {};
    el = ctx.nodeName || is.fn(ctx.node) ? ctx : el;
    el = el.node ? el.node() : el;
    el = el.host || el;
    return args > 1 && value === false ? el.removeAttribute(name) : args > 1 ? (el.setAttribute(name, value), value) : el.attributes.getNamedItem(name) && el.attributes.getNamedItem(name).value;
  };
};

export default exports;